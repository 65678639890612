// Module imports
import lottie from 'lottie-web';
import smoothscroll from 'smoothscroll-polyfill';
 
// Smooth scrolling polyfill
smoothscroll.polyfill();

document.addEventListener("DOMContentLoaded", function(event) { 
    var anim_one_wrapper = document.getElementById('anim_one');
    var anim_one = lottie.loadAnimation({
        wrapper: anim_one_wrapper,
        path: 'static/achterbahn.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: "achterbahn", 
    });

    var anim_two_wrapper = document.getElementById('anim_two');
    var anim_two = lottie.loadAnimation({
        wrapper: anim_two_wrapper,
        path: 'static/farbrolle.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: "farbrolle", 
    });

    var anim_three_wrapper = document.getElementById('anim_three');
    var anim_three = lottie.loadAnimation({
        wrapper: anim_three_wrapper,
        path: 'static/kran.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: "kran", 
    });

    // Fade in teaser background
    document.querySelector(".teaser").classList.remove("fade");
    // Initialize remaining faders
    handleFading();
});

document.addEventListener("scroll", handleFading);
function handleFading(e) {
    // Fade in team cards
    let teamcards = document.querySelectorAll(".teamcard");
    Array.from(teamcards).forEach(teamcard => {
        let rect = teamcard.getBoundingClientRect();
        if(rect.top > (window.innerHeight * 0.95)) {
            return;
        }
        teamcard.classList.remove("fade");
    });
    // Fade in (h1) headings (and sdk.group logo heading)
    let headings = document.querySelectorAll("h1.fade, img.fade");
    Array.from(headings).forEach(heading => {
        let rect = heading.getBoundingClientRect();
        if(rect.top > (window.innerHeight * 0.92)) {
            return;
        }
        heading.classList.remove("fade");
    });
}

window.openTeam = function(e) {
    var id = e.srcElement.parentNode.dataset.id;
    var overlay = document.getElementById('overlay-' + id);
    if (!overlay) {
        return;
    }

    document.querySelector("html").style.overflowY = "hidden";

    overlay.classList.add('is-visible');
}
window.closeTeam = function() {
    document.querySelector("html").style.overflowY = "auto";
    
    var elements = document.querySelectorAll('*[id^="overlay"]');
    elements.forEach(function(elem) {
        elem.classList.remove('is-visible');
    }); 
}

window.openMobileMenu = function(e) {
    var menu = document.querySelector(".mobile-menu");
    if (!menu) {
        return;
    }

    menu.classList.add('is-visible');
}
window.closeMobileMenu = function(e) {
    var menu = document.querySelector(".mobile-menu");
    if (!menu) {
        return;
    }

    menu.classList.remove('is-visible');
}